import { ModalHeader } from '@dfb/dfb-ui-react/brands/dachmarke';
import { useTranslation } from 'react-i18next'
import DfbLogo from '../../assets/images/dfb-logo-L.svg'

import React from 'react'

export const ModalInnerHeader = (props: any) => {
  const { t } = useTranslation()

  return (
    <ModalHeader>
      <div className={`modal__header ${props.className}`}>
        <div className="modal__header--logo">
          <img src={DfbLogo} alt={t('header.logo.alt') as string}></img>{t('header.brand')}
        </div>
        <div className="modal__header--keyvisual">platzhalter keyvisual</div>
      </div>
    </ModalHeader>
  )
}

