import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNav } from '../../hooks/useNav'
import SectionHeader from '../atom/section-header'
import VideoPlayer from '../atom/video-player'
import BKAnpfiff from '../../assets/images/bk-anpfiff.png'

const SectionAnpfiff = ({ id }: { id: string }) => {
  const { t } = useTranslation()
  const ref = useNav(id)

  return (
    <div>
      <div className='section-anpfiff__blob-wrapper'>
        <div className='section-anpfiff__blob'>
          <img className='section-anpfiff__blob--image' src={BKAnpfiff} alt='' />
        </div>
      </div>
      <div className='section section-anpfiff' id={id} ref={ref}>
        <div className='grid-wrapper'>
          <div className='grid-container'>
            <div className='grid-cell--seven grid-offset-pre--one'>
              <SectionHeader
                title={t('anpfiff.intro.title') as string}
                subtitle={t('anpfiff.intro.headline') as string}
                text={t('anpfiff.intro.copy') as string}
                extendedText={t('anpfiff.intro.copy-extended') as string}
                externalLink={t('anpfiff.intro.link-href') as string}
                linkLabel={t('anpfiff.intro.link-label') as string}
                hasHalfWidthAnimation={false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='grid-wrapper'>
        <div className='grid-container'>
          <div className='section-anpfiff__video grid-offset-pre--two grid-offset-post--two'>
            <VideoPlayer videoId={t('anpfiff.video-id') as string} videoIdCc={t('anpfiff.video-id-cc') as string} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionAnpfiff
