import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNav } from '../../hooks/useNav'
import { ReactComponent as Blob } from '../../assets/images/blob-anpfiff.svg';
import { ReactComponent as ScrollDown } from '../../assets/ui-icons/scroll_down.svg'

const Hero = ({ id, next }: { id: string; next: string }) => {
  const { t } = useTranslation()
  const ref = useNav(id)
  
  const scrollDown = () => {
    document.getElementById(next)?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="hero__container" id={id} ref={ref}>
      <div className="hero__blob-image">
        <Blob />
      </div>
      <div className="grid-container">
        <div className="grid-wrapper">
          <div className="hero__text">
            <p className="hero__text hero__text--small">{t('hero.smallText')}</p>
            <h1 className="h2 hero__text--main">{t('hero.mainText')}</h1>
          </div>
        </div>
      </div>
      <div className="hero__btn" onClick={scrollDown}>
        <ScrollDown />
      </div>
    </div>
  )
}

export default Hero
