import Kickoff from '../assets/pictograms/Kickoff.svg'
import Coaching from '../assets/pictograms/Coaching.svg'
import Footballer from '../assets/pictograms/Footballer.svg'
import Sportoekonomie from '../assets/pictograms/Sportoekonomie.svg'
import Unternehmensfuehrung from '../assets/pictograms/Unternehmensfuehrung.svg'

export interface NavigationItem {
  label: string
  href: string
  imghref: string
}

const navigation: NavigationItem[] = [
  {
    label: 'anpfiff',
    href: '#anpfiff',
    imghref: Kickoff
  },
  {
    label: 'taktik',
    href: '#taktik',
    imghref: Coaching
  },
  {
    label: 'spielt-mit',
    href: '#spielt-mit',
    imghref: Footballer
  },
  {
    label: 'vereinskasse',
    href: '#vereinskasse',
    imghref: Sportoekonomie
  },
  {
    label: 'teamplayer',
    href: '#teamplayer',
    imghref: Unternehmensfuehrung
  }
]

export default navigation
