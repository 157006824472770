import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react'
import { useNav } from '../../hooks/useNav'
import { useViewport } from '../../hooks/useViewport'
import SectionCard from '../atom/section-card'
import VideoPlayer from '../atom/video-player'
import SectionHeader from '../atom/section-header'
import CardSlider from '../element/card-slider'
import { CardContentModal } from '../module/card-content-modal'
import FieldXL from '../../assets/images/field-arrows-XL.svg'
import FieldXS from '../../assets/images/field-arrows-XS.svg'
import AnimFadeInUp from '../atom/AnimFadeInUp';

export interface ModalCardData {
  teaser: string
  title: string
  description: string
  modal: {
    'text-left': string
    'text-right': string
    images: [
      {
        image: string
        label: string
      }
    ]
    extras: {
      linksTitle: string
      links: [
        {
          title: string
          label: string
          url: string
        }
      ]
      downloads: [
        {
          label: string
          url: string
        }
      ]
    }
  }
}

const SectionTaktik = ({ id }: { id: string }) => {
  const { t } = useTranslation()
  const ref = useNav(id)

  const { width } = useViewport()

  // state modal data
  const [isCardModalOpen, setIsCardModalOpen] = useState(false)
  const [currentModalData, setCurrentModalData] = useState<ModalCardData | null>(null)

  const onClose = () => {
    setIsCardModalOpen(false)
  }

  const onOpenModal = (object: any) => {
    setCurrentModalData(object)
    setIsCardModalOpen(true)
  }

  const cardTemplate = (key: string) => (
    <SectionCard
      teaser={t(`${key}.teaser`) as string}
      titel={t(`${key}.title`) as string}
      description={t(`${key}.description`) as string}
      linkLabel={t(`${key}.link-label`) as string}
      onClick={() => {
        onOpenModal(t(key, { returnObjects: true }))
      }}
    />
  )

  const cards = [
    {
      gridClasses: 'grid-cell--five grid-offset-pre--one',
      wrapperClasses: 'section-taktik__card section-taktik__card--first',
      content: cardTemplate('taktik.cards.card1')
    },
    {
      gridClasses: 'grid-offset-pre--one',
      wrapperClasses: 'section-taktik__card section-taktik__card--second',
      content: cardTemplate('taktik.cards.card2')
    },
    {
      gridClasses: 'grid-cell--five grid-offset-pre--one',
      wrapperClasses: 'section-taktik__card section-taktik__card--third',
      content: cardTemplate('taktik.cards.card3')
    },
    {
      gridClasses: 'grid-offset-pre--one',
      wrapperClasses: 'section-taktik__card section-taktik__card--fourth',
      content: cardTemplate('taktik.cards.card4')
    }
  ]

  return (
    <div className='section section-taktik' id={id} ref={ref}>
      {currentModalData && (
        <CardContentModal
          visible={isCardModalOpen}
          data={currentModalData}
          close={onClose}
          fullSize={width < 992 ? true : false}
          title={currentModalData.title}
          textLeft={currentModalData.modal['text-left']}
          textRight={currentModalData.modal['text-right']}
          images={currentModalData.modal.images}
          extras={currentModalData.modal.extras}
        />
      )}

      <img className='section-taktik__background' src={width < 992 ? FieldXS : FieldXL} alt='' />

      <div className='grid-wrapper'>
        <div className='grid-container'>
          <div className='grid-cell--half grid-offset-pre--one'>
            <SectionHeader
              title={t('taktik.intro.title') as string}
              subtitle={t('taktik.intro.headline') as string}
              text={t('taktik.intro.copy') as string}
              extendedText={t('taktik.intro.copy-extended') as string}
              externalLink={t('taktik.intro.link-href') as string}
              linkLabel={t('taktik.intro.link-label') as string}
              hasHalfWidthAnimation={false}
            />
          </div>
        </div>
      </div>

      {/*<Reveal repeat trigger={<div/>}>*/}
      {/*  <Timeline>*/}
      <div className='grid-wrapper'>
        <div className='grid-container'>
          {width < 992 ? (
            <CardSlider autoHeight={true}>
              {cards.map((card, index) => (
                <SwiperSlide key={index} className='section-taktik__card--slider'>
                  {card.content}
                </SwiperSlide>
              ))}
            </CardSlider>
          ) : (
            <React.Fragment>
              {cards.map((card, index) => (
                <div key={index} className={card.gridClasses}>
                  {/*<AnimFadeInUp pos={index}>*/}
                  <div className={card.wrapperClasses}>{card.content}</div>
                  {/*</AnimFadeInUp>*/}
                </div>
              ))}
            </React.Fragment>
          )}
        </div>
      </div>

      <div className='grid-wrapper section-taktik__video-container'>
        <div className='grid-container'>
          <AnimFadeInUp pos={cards.length + 1}>
            <div className='section-taktik__video grid-offset-pre--two grid-offset-post--two'>
              <VideoPlayer videoId={t('taktik.video-id') as string} videoIdCc={t('taktik.video-id-cc') as string} />
            </div>
          </AnimFadeInUp>
        </div>
      </div>

      {/*</Timeline>*/}
      {/*</Reveal>*/}
    </div>
  )
}

export default SectionTaktik
