import React from "react";
import BKTeamplayers from '../../assets/images/bk-teamplayers.png';


const BlobImage = () => {
    return(
        <div className="blob">
            <img className="blob__image" src={BKTeamplayers} alt="" />
        </div>  
    )
}

export default BlobImage;