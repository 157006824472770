import React, { useState, createContext } from 'react'

export interface ISection {
  id: string
  ref: React.MutableRefObject<any>
}
export type NavigationContextType = {
  sections: ISection[]
  addSection: (sectionId: string, ref: React.MutableRefObject<any>) => void
  activeSection: string
  setActiveSection: (sectionId: string) => void
}

export const NavigationContext = createContext<NavigationContextType | null>(null)

const NavigationProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [sections, setSections] = useState<ISection[]>([])
  const [activeSection, setActiveSection] = useState('')

  const addSection = (sectionId: string, sectionRef: React.MutableRefObject<any>) => {
    const sectionExists = sections.find(section => section.id === sectionId) || null

    if (!sectionExists) {
      const newSection: ISection = {
        id: sectionId,
        ref: sectionRef
      }
      setSections(prevSections => {
        const newState = [...prevSections, newSection]
        return newState
      })
    }
  }

  return (
    <NavigationContext.Provider value={{ sections, addSection, activeSection, setActiveSection }}>
      {children}
    </NavigationContext.Provider>
  )
}

export default NavigationProvider
