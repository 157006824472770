import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as DOMPurify from 'dompurify'
import { Anchor, Button } from '@dfb/dfb-ui-react/brands/dachmarke'
import { ReactComponent as info } from '../../assets/ui-icons/info.svg';

type DataSectionHeader = {
  title?: string
  subtitle?: string
  text?: string
  extendedText?: string
  externalLink?: string
  linkLabel?: string
  className?: string
  hasHalfWidthAnimation?: boolean
  hasContentExtraWidth?: boolean
  isHinweisVisible?: boolean
  onHinweisClick?: () => void
}


const SectionHeader = (props: DataSectionHeader) => {
  const {t} = useTranslation()
  const [isTextVisible, setIsTextVisible] = useState(false)

  const onClick = () => {
    setIsTextVisible(!isTextVisible)
  }

  const onButtonClick = () => {
    console.log('button')
    props.onHinweisClick && props.onHinweisClick()
  }

  return (
    <div>
      {/*<Reveal repeat trigger={<div/>}>*/}
      {/*  <Timeline>*/}
      {/*<AnimFadeInLeft pos={0} isFarLeft={props.hasHalfWidthAnimation}>*/}
      <h2
        className={`section-head__title ${
          props.hasHalfWidthAnimation ? 'section-head__title--anim-half' : 'section-head__title--anim-letter'
        }`}>
        {props.title}
      </h2>
      {/*</AnimFadeInLeft>*/}
      <div
        className={`section-head__content ${props.hasContentExtraWidth ? 'section-head__content--extraWidth' : ''}`}>
        {/*<AnimFadeInUp pos={1}>*/}
        <div className={`${props.isHinweisVisible ? 'd-flex-column' : ''}`}>
          <h4 className='section-head__subtitle'>{props.subtitle}</h4>
          {props.isHinweisVisible &&
          <Button type={'secondary'} onClick={onButtonClick}
                  label={'Hinweis'} icon={info}/>
          }
        </div>
        {/*</AnimFadeInUp>*/}
        {/*<AnimFadeInUp pos={2}>*/}
        <div>
          {props.text &&
          props.text.split('{{ABSATZ}}').map((paragraph, index) => (
            <p
              key={index}
              className='section-head__text'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(paragraph, {USE_PROFILES: {html: true}})
              }}></p>
          ))}
          {props.extendedText &&
          props.extendedText.split('{{ABSATZ}}').map((paragraph, index) => (
            <p
              key={index}
              className={`section-head__extended-text ${
                isTextVisible ? 'section-head__text' : 'section-head__text--hidden'
              }`}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(paragraph, {USE_PROFILES: {html: true}})
              }}></p>
          ))}

          {props.extendedText && (
            <div className='section-head__more' onClick={onClick}>
              <Anchor
                type={'secondary'}
                label={(isTextVisible ? t('common.read-less') : t('common.read-more')) as string}
              />
            </div>
          )}

          {!props.extendedText && props.externalLink && (
            <div className='section-head__more'>
              <a
                className={`dfb-Anchor dfb-Anchor--secondary`}
                href={props.externalLink}
                target='_blank'
                rel='noreferrer'>
                      <span
                        className='dfb-Anchor-label'>{props.linkLabel ? props.linkLabel : t('common.read-more')}</span>
              </a>
            </div>
          )}
        </div>
        {/*</AnimFadeInUp>*/}
      </div>
      {/*</Timeline>*/}
      {/*</Reveal>*/}
    </div>
  )
}

export default SectionHeader
