import PropTypes from 'prop-types'
import { ReactComponent as ArrowUpRight } from '../../assets/ui-icons/arrow_Up_Right.svg'

//link text button in footer info container to open external links
const LinkButtonAtom = (props: any) => {

  return (
    <>
      <a
        href={props.href}
        target="_blank"
        rel="noreferrer"
        className='info__link paragraph-big'>
        {props.title}
        <ArrowUpRight className='info__link--icon paragraph-big' />
      </a>
    </>
  );
}

export default LinkButtonAtom

LinkButtonAtom.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
}