import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNav } from '../../hooks/useNav'

import FooterInfoAtom from '../atom/footer-info'
import DownloadAtom from '../atom/download'
import LinkButtonAtom from '../atom/link-button'

import { Anchor } from '@dfb/dfb-ui-react/brands/dachmarke'

import { ReactComponent as Facebook } from '../../assets/ui-icons/facebook_border.svg'
import { ReactComponent as Instagram } from '../../assets/ui-icons/instagram.svg'
import { ReactComponent as Twitter } from '../../assets/ui-icons/twitter_border.svg'
import { ReactComponent as Youtube } from '../../assets/ui-icons/youTube.svg'
import FundingImg from '../../assets/images/bMWK_NKI_logos.svg'
import GutesSpielImg from '../../assets/images/DFB-Gutes-Spiel_logo.svg'

declare global {
  interface UserCentrics {
    showSecondLayer(): void
  }

  interface Window {
    UC_UI: UserCentrics
  }
}

const FooterElement = ({ id }: { id: string }) => {
  const { t } = useTranslation()
  const ref = useNav(id)

  type Link = {
    'link-label': string
    'link-href': string
    'link-icon'?: string
  }

  const links: Link[] = t(`information.info-area.links`, {
    returnObjects: true
  })
  const downloads: Link[] = t(`information.download-area.links`, {
    returnObjects: true
  })
  const general: Link[] = t(`footer.general.links`, {
    returnObjects: true
  })
  const socialMedia: Link[] = t(`footer.social-media.links`, {
    returnObjects: true
  })
  const socialMediaIcons: { [key: string]: any } = {
    facebook: Facebook,
    instagram: Instagram,
    twitter: Twitter,
    youtube: Youtube
  }

  const showUserCentricsUi = () => {
    window.UC_UI.showSecondLayer()
  }

  return (
    <footer className='grid-container footer__container' id={id} ref={ref}>
      <div className='grid-wrapper footer__info'>
        <div>
          <FooterInfoAtom title={t('information.info-area.headline')} text={t('information.info-area.copy')} />
          {Array.isArray(links) &&
            links.map((item, index) => (
              <LinkButtonAtom key={index} title={item['link-label']} href={item['link-href']} />
            ))}
        </div>

        <div>
          <FooterInfoAtom title={t('information.download-area.title')} text={t('information.download-area.copy')} />
          {Array.isArray(downloads) &&
            downloads.map((item, index) => (
              <DownloadAtom key={index} headline={item['link-label']} linkref={item['link-href']} />
            ))}
        </div>
      </div>
      <div className='footer__general'>
        <div className='grid-wrapper footer__general--wrapper'>
          <div className='footer__general--left'>
            <div className='footer__title'>{t('footer.social-media.title')}</div>
            <div className='footer__content footer__content--social'>
              {Array.isArray(socialMedia) &&
                socialMedia.map((item, index) => (
                  <a
                    key={index}
                    href={item['link-href']}
                    className={`social__${item['link-icon']}`}
                    aria-label={item['link-label']}
                    target='_blank'
                    rel='noreferrer'>
                    {item['link-icon']
                      ? socialMediaIcons[item['link-icon']]
                        ? React.createElement(socialMediaIcons[item['link-icon']], {})
                        : item['link-label']
                      : item['link-label']}
                  </a>
                ))}
            </div>
            <div className='footer__title'>{t('footer.general.title')}</div>
            <div className='footer__content footer__content--imprint'>
              {Array.isArray(general) &&
                general.map((item, index) => (
                  <a key={index} className={`dfb-Anchor`} href={item['link-href']} target='_blank' rel='noreferrer'>
                    <span className='dfb-Anchor-label'>{item['link-label']}</span>
                  </a>
                ))}
              <Anchor label={t('footer.general.cookie.link-label') as string} onClick={showUserCentricsUi} />
            </div>
            <p className='footer__copyright'>{t('footer.copyright', { year: new Date().getFullYear() })}</p>
          </div>
          <div className='footer__general--right'>
            <div className='footer__gutes-spiel'>
              <img src={GutesSpielImg} alt='' />
            </div>
            <div className='footer__funding'>
              <img src={FundingImg} alt='' />
              <p className='paragraph-small'>{t('footer.funding-indicator')}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterElement
