import { useEffect, useContext } from 'react'
import { NavigationContext, NavigationContextType } from '../context/NavigationContext'

export const useIntersectionNavigation = () => {
  const { sections, setActiveSection } = useContext(NavigationContext) as NavigationContextType

  useEffect(() => {
    let previousScrollPosition = 0
    let scrollDirection = 'down'

    const options = {
      rootMargin: `-100px`,
      threshold: 0
    }

    const getTargetSectionId = (entry: IntersectionObserverEntry) => {
      const index = sections.findIndex(section => section.ref.current === entry.target)

      if (index >= sections.length - 1) {
        return entry.target.getAttribute('id')
      } else {
        return sections[index + 1].id
      }
    }

    const shouldUpdate = (entry: IntersectionObserverEntry) => {
      if (scrollDirection === 'down' && !entry.isIntersecting) {
        return true
      }
      if (scrollDirection === 'up' && entry.isIntersecting) {
        return true
      }

      return false
    }

    const onIntersect: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach(entry => {
        let targetId = ''
        if (window.scrollY >= previousScrollPosition) {
          scrollDirection = 'down'
          targetId = getTargetSectionId(entry) ?? ''
        } else {
          scrollDirection = 'up'
          targetId = entry.target.getAttribute('id') ?? ''
        }

        previousScrollPosition = window.scrollY

        if (shouldUpdate(entry)) {
          setActiveSection(targetId)
        }
      })
    }

    if (sections.length) {
      sections.forEach(section => {
        const observer = new IntersectionObserver(onIntersect, options)
        observer.observe(section.ref.current)
      })
    }
  }, [sections, setActiveSection])
}
