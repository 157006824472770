import React from 'react'
import { Swiper } from 'swiper/react'
import { Pagination } from 'swiper'

const CardSlider = (props: any) => {
  const pagination = {
    clickable: true,
    allowTouchMove: true
  }

  return (
    <div className='card-slider'>
      <Swiper
        modules={[Pagination]}
        pagination={pagination}
        allowTouchMove={pagination.allowTouchMove}
        autoHeight={props.autoHeight ?? false}>
        {props.children}
      </Swiper>
    </div>
  )
}

export default CardSlider
