import { Tween } from 'react-gsap';

const AnimFadeInUp = (props: any) => (
  <Tween
    from={{opacity: 0.5, y: '50px'}}
    duration={0.8}
    position={props.pos}
    ease={'elastic.out(0.2, 0.1)'}
  >
    {props.children}
  </Tween>
);

export default AnimFadeInUp
