import { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import * as DOMPurify from 'dompurify'
import { NavigationContext, NavigationContextType } from '../../context/NavigationContext'
import StickySection from '../element/sticky-section'
import DfbLogo from '../../assets/images/dfb-logo-L.svg'
import Navigation from '../../data/navigation'
import BurgerButton from '../atom/burger-button'
import FundingImg from '../../assets/images/bMWK_NKI_logos.svg'
import Keyvisual from '../../assets/images/keyvisual.svg'
import { ReactComponent as Blob } from '../../assets/images/blob-anpfiff.svg'

const Header = () => {
  const { t } = useTranslation()
  const { activeSection } = useContext(NavigationContext) as NavigationContextType

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
    const body = document.querySelector('body')
    body!.style.overflow = !isMenuOpen ? 'hidden' : 'auto'
    window.scrollTo(0, 0)
  }

  const navigate = (id: string) => {
    toggleMenu()
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <StickySection />
      <div className={`header__wrapper`}>
        <nav className='header__nav'>
          <a href='/' className='header__logo'>
            <img
              src={DfbLogo}
              title={`${t(`header.logo.title`)}`}
              alt={`${t(`header.logo.alt`)}`}
              className='header__img'
            />
            <div>{t(`header.brand`)}</div>
          </a>
          <img className='header__brand' src={Keyvisual} alt='Logo Anstoss für Grün' />

          <ul className={`nav__menu ${isMenuOpen ? 'nav__collapse' : ''}`}>
            {Navigation &&
              Navigation.map(({ label, href, imghref }, index) => (
                <li key={index} className={`nav__item ${activeSection === label ? 'nav__item--active' : ''}`}>
                  <img
                    src={imghref}
                    alt={`${t(`nav.${label}.menu`)}`}
                    className='nav__img'
                    onClick={e => navigate(label)}
                  />
                  <span
                    className='nav__link'
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(t(`nav.${label}.menu`) || '', { USE_PROFILES: { html: true } })
                    }}
                    onClick={e => navigate(label)}></span>
                </li>
              ))}
            <div className='nav__logo-box'>
              <img src={FundingImg} alt='' />
              <p className='paragraph-small'>{t('nav.funding-indicator')}</p>
            </div>
            <div className='nav__bubble-img'>
              <Blob />
            </div>
          </ul>

          <BurgerButton
            className={`${isMenuOpen ? 'burger-button--is-active' : ''}`}
            onClick={toggleMenu}
            label={t(`nav.label`)}></BurgerButton>
        </nav>
      </div>
    </>
  )
}

export default Header
