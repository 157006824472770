import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNav } from '../../hooks/useNav'
import SectionHeader from '../atom/section-header'
import VideoPlayer from '../atom/video-player'
import Massnahmen from './massnahmen'
import BKSpiel from '../../assets/images/bk-spielt-mit.png'

const SectionSpiel = ({ id }: { id: string }) => {
  const { t } = useTranslation()
  const ref = useNav(id)

  return (
    <div id={id} ref={ref}>
      <div className='section-spiel__blob-wrapper'>
        <div className='section-spiel__blob'>
          <img className='section-spiel__blob--image' src={BKSpiel} alt='' />
        </div>
      </div>

      <div className='section section-spiel'>
        <div className='grid-wrapper'>
          <div className='grid-container'>
            <div className='grid-cell--half grid-offset-pre--one'>
              <SectionHeader
                title={t('spielt-mit.intro.title') as string}
                subtitle={t('spielt-mit.intro.headline') as string}
                text={t('spielt-mit.intro.copy') as string}
                extendedText={t('spielt-mit.intro.copy-extended') as string}
                externalLink={t('spielt-mit.intro.link-href') as string}
                linkLabel={t('spielt-mit.intro.link-label') as string}
                hasHalfWidthAnimation={false}
              />
            </div>
          </div>
        </div>

        <div className='grid-wrapper'>
          <div className='grid-container'>
            <div className='section-spiel__video grid-offset-pre--two grid-offset-post--two'>
              <VideoPlayer
                videoId={t('spielt-mit.video-id') as string}
                videoIdCc={t('spielt-mit.video-id-cc') as string}
              />
            </div>
          </div>
        </div>
      </div>
      <Massnahmen />
    </div>
  )
}

export default SectionSpiel
