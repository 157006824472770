import React, { Suspense } from 'react'
import './App.scss'
import { Spinner } from '@dfb/dfb-ui-react/brands/dachmarke/index'

import NavigationProvider from './context/NavigationContext'
import { useScrollDirection } from './hooks/useScrollDirection'
import Header from './components/module/header'
import Hero from './components/module/hero'
import SectionTaktik from './components/module/section-taktik'
import SectionAnpfiff from './components/module/section-anpfiff'
import SectionSpiel from './components/module/section-spiel'
import SectionVereinskasse from './components/module/section-vereinskasse'
import SectionTeamplayer from './components/module/section-teamplayer'
import Footer from './components/element/footer'

import { useIntersectionNavigation } from './hooks/useIntersectionNavigation'

const Page = () => {
  useIntersectionNavigation()
  const { lastScrollDirection } = useScrollDirection()

  return (
    <div className={`container ${lastScrollDirection ? 'scrolling-'.concat(lastScrollDirection?.toLowerCase()) : ''}`}>
      <Header />
      <Hero id='hero' next='anpfiff' />
      <SectionAnpfiff id='anpfiff' />
      <SectionTaktik id='taktik' />
      <SectionSpiel id='spielt-mit' />
      <SectionVereinskasse id='vereinskasse' />
      <SectionTeamplayer id='teamplayer' />
      <Footer id='footer' />
    </div>
  )
}

const Loader = () => {
  return (
    <div className='loader'>
      <Spinner showLoader></Spinner>
    </div>
  )
}

// here app catches the suspense from page in case translations are not yet loaded
const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <NavigationProvider>
        <Page />
      </NavigationProvider>
    </Suspense>
  )
}
export default App
