import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Masonry from 'react-masonry-css'

import { useNav } from '../../hooks/useNav'
import SectionHeader from '../atom/section-header'
import SectionCard from '../atom/section-card'
import GermanyMap from '../element/germany-map'

import { Spinner } from '@dfb/dfb-ui-react/brands/dachmarke'

export interface FundingOpportunityData {
  region: string
  category: string
  title: string
  subtitle: string
  description: string
  link: string
}

const SectionVereinskasse = ({ id }: { id: string }) => {
  const { t } = useTranslation()
  const ref = useNav(id)

  const [isLoading, setIsLoading] = useState(false)
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true)
  const [selectedRegion, setSelectedRegion] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [allItems, setAllItems] = useState<FundingOpportunityData[]>([])
  const [filteredItems, setFilteredItems] = useState<FundingOpportunityData[]>([])

  const breakpointColumnsObj = {
    default: 3,
    1280: 3,
    992: 2,
    576: 1
  }

  useEffect(() => {
    setIsLoading(true)
    fetch('/data/kassen.json')
      .then(res => res.json())
      .then(
        result => {
          setAllItems(result)
          setIsLoading(false)
        },
        error => {
          setIsLoading(false)
          console.log(error)
        }
      )
  }, [setIsLoading, setAllItems])

  const filterItems = () => {
    const filteredItems: FundingOpportunityData[] = allItems.filter(
      (item: FundingOpportunityData) => item.region === selectedRegion && item.category === selectedCategory
    )

    setFilteredItems(filteredItems)
    setIsSearchActive(true)
  }

  const enableSearchButton = (region: string, category: string) => {
    setIsSearchButtonDisabled(region === '' || category === '')
  }

  const onCategoryChange = (category: string) => {
    setSelectedCategory(category)
    enableSearchButton(selectedRegion, category)
    setIsSearchActive(false)
  }

  const onRegionChange = (region: string) => {
    setSelectedRegion(region)
    enableSearchButton(region, selectedCategory)
    setIsSearchActive(false)
  }

  const onSearch = () => {
    filterItems()
  }

  const content = isLoading ? (
    <Spinner showLoader={isLoading} />
  ) : (
    <>
      <div className='section-vereinskasse__map'>
        <GermanyMap
          onCategoryChange={onCategoryChange}
          onRegionChange={onRegionChange}
          onSearchClicked={onSearch}
          selectedCategory={selectedCategory}
          selectedRegion={selectedRegion}
          isSearchButtonDisabled={isSearchButtonDisabled}
        />
      </div>
      {isSearchActive && (
        <div className='section-vereinskasse__results'>
          <div className='grid-container'>
            <div className='grid-cell--ten margin-lr-auto'>
              <h4 className='section-vereinskasse__results-headline'>
                {t([`vereinskasse.map.regions.${selectedRegion}`, 'vereinskasse.map.regions.all'])}:{' '}
                {t([`vereinskasse.map.categories.${selectedCategory}`, 'vereinskasse.map.categories.all'])}
              </h4>
              <div className='massnahmen__status d-flex grid-cell--half'>
                <span>{filteredItems.length}</span>
                <span>{t(`vereinskasse.map.results-found`)}</span>
              </div>
            </div>
          </div>

          <div className='grid-container'>
            <div className='grid-cell--ten margin-lr-auto'>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className='massnahmen__masonry'
                columnClassName='massnahmen__masonry-column'>
                {filteredItems.map((card: FundingOpportunityData, index: number) => (
                  <div className='massnahmen massnahmen-col' key={index}>
                    <SectionCard
                      titel={card.title}
                      subtitel={card.subtitle}
                      description={card.description}
                      key={index}
                      link={card.link}
                      linkLabel={t(`vereinskasse.map.card.link-label`) as string}
                    />
                  </div>
                ))}
              </Masonry>
            </div>
          </div>
        </div>
      )}
    </>
  )

  return (
    <div className='section section-vereinskasse' id={id} ref={ref}>
      <div className='grid-wrapper'>
        <div className='grid-container'>
          <div className='grid-cell--half grid-offset-pre--five'>
            <SectionHeader
              title={`${t('vereinskasse.intro.title')}`}
              subtitle={`${t('vereinskasse.intro.headline')}`}
              text={`${t('vereinskasse.intro.copy')}`}
              hasHalfWidthAnimation={true}
            />
          </div>
        </div>
        {content}
      </div>
    </div>
  )
}

export default SectionVereinskasse
