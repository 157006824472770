import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNav } from '../../hooks/useNav'
import { useViewport } from '../../hooks/useViewport'

import SectionHeader from '../atom/section-header'
import LogoCard from '../../components/atom/logo-card'
import BlobImage from '../atom/blob-image'
import UEFALogo from '../../assets/images/euro2024.svg'
import BMWKI_NKI from '../../assets/images/bMWK_NKI_logos.svg'
import DOSBLogo from '../../assets/images/dosb-logo-full.svg'

const SectionTeamplayer = ({ id }: { id: string }) => {
  const { t } = useTranslation()
  const ref = useNav(id)

  const { width } = useViewport()

  return (
    <div className='section section-teamplayer' id={id} ref={ref}>
      <div className='grid-wrapper'>
        <div className='grid-container'>
          <div className='grid-cell--half grid-offset-pre--five'>
            <SectionHeader
              title={t('teamplayer.intro.title') as string}
              subtitle={t('teamplayer.intro.headline') as string}
              text={t('teamplayer.intro.copy') as string}
              extendedText={t('teamplayer.intro.copy-extended') as string}
              externalLink={t('teamplayer.intro.link-href') as string}
              linkLabel={t('teamplayer.intro.link-label') as string}
              hasHalfWidthAnimation={true}
            />
          </div>

          <div className='section-teamplayer__content'>
            <div className='section-teamplayer__image'>
              <BlobImage />
            </div>

            <div className='section-teamplayer__logos'>

              {width < 992 ? (
                <div className='section-teamplayer__logos--outer-flex'>
                  <LogoCard
                    logo={BMWKI_NKI}
                    link={t('teamplayer.logo-links.BMWKI-NKI') as string}
                    className='section-teamplayer__logos--bmwki'
                  />
                  <div className='section-teamplayer__logos--inner-flex'>
                  <LogoCard
                      logo={UEFALogo}
                      link={t('teamplayer.logo-links.UEFA') as string}
                      className='section-teamplayer__logos--uefa'
                    />
                    <LogoCard
                      logo={DOSBLogo}
                      link={t('teamplayer.logo-links.DOSB') as string}
                      className='section-teamplayer__logos--dosb'
                    />
                  </div>
                  
                </div>
              ) : (
                <React.Fragment>
                  <LogoCard
                    logo={UEFALogo}
                    link={t('teamplayer.logo-links.UEFA') as string}
                    className='section-teamplayer__logos--uefa'
                  />
                  <LogoCard
                    logo={BMWKI_NKI}
                    link={t('teamplayer.logo-links.BMWKI-NKI') as string}
                    className='section-teamplayer__logos--bmwki'
                  />
                  <LogoCard
                    logo={DOSBLogo}
                    link={t('teamplayer.logo-links.DOSB') as string}
                    className='section-teamplayer__logos--dosb'
                  />
                </React.Fragment>
                
              )}

              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionTeamplayer
