import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Anchor } from '@dfb/dfb-ui-react/brands/dachmarke'

const VideoPlayer = (props: any) => {
  const {t} = useTranslation()

  const [videoId, setVideoId] = useState(props.videoId)

  const videoIdCc = props.videoIdCc && Number.isInteger(Number.parseInt(props.videoIdCc)) ? props.videoIdCc : ''

  const isDefaultVideo = () => videoId === props.videoId

  const toggleVideo = () => {
    setVideoId(isDefaultVideo() ? props.videoIdCc : props.videoId)
  }

  return (
    <>
      <div className={`video-player ${props.className}`}>
        <div className='video-player__wrapper'>
          <iframe
            title='Player'
            sandbox='allow-scripts allow-same-origin allow-presentation'
            src={`https://tv.dfb.de/hlsPlayer_frame.php?view=${videoId}&amp;startplaying=false&amp;download=false&amp;t=0&amp`}
            marginHeight={0}
            marginWidth={0}
            frameBorder='0'
            scrolling='no'
            allowFullScreen={true}></iframe>
        </div>
      </div>

      {videoIdCc && (
        <div className='video-player__cc'>
          <Anchor
            onClick={toggleVideo}
            label={`${isDefaultVideo() ? t('common.video-cc-on') : t('common.video-cc-off')}`}
          />
        </div>
      )}
    </>
  )
}

VideoPlayer.propTypes = {
  className: PropTypes.string,
  videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  videoIdCc: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default VideoPlayer
